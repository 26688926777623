@media (min-width: 650px) {
  .row {
    display: flex;
    align-items: center;
  }
  .label {
    width: 260px;
    margin-right: 30px;
    text-align: left;
  }
}
@media (max-width: 649px) {
  .row {
    align-items: center;
    padding-top: 26px;
    padding-bottom: 26px;
  }
  .label {
    text-align: left;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 700;
  }
  .center_input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.inner_row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inner_row2 {
  /*  text-align: right;*/
  width: 110px;
}

.title {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 6px;
  color: #444;
}
.title2 {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #444;
}
.wrapper {
  width: 700px;
  max-height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  overflow: scroll;
  padding: 10px;
}
.button_wrapper {
  margin-top: 1.5rem;
  display: flex;
  padding-left: 130px;
  padding-right: 130px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 200px;
}
.subject_id {
  color: #186bcc;
  font-size: 26px;
}
.confirmation {
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}
.ric_image {
  align-self: center;
  margin-bottom: 20px;
  height: 360px;
  width: 200px;
}
.margin_bottom_10 {
  margin-bottom: 10px;
}
