.header_wrapper {
  height: 55px;
  width: 100vw;
  background: #fff;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  margin-left: 1rem;
}
.text_logo {
  align-self: center;
  margin-left: 14px;
  font-size: 20px;
  font-weight: 500;
  color: #454545;
}
.settings_icons_wrapper {
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.apps_icon_wrapper {
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hflex {
  display: flex;
}
.username_text {
}
.staging_text {
  color: var(--heading-red-text);
  font-weight: 700;
}
.name_role_set {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  color: #777;

  text-align: right;
}

@media screen and (min-width: 480px) {
  .settings_icon {
    display: block;
    margin-left: 20px;
  }
}
@media screen and (max-width: 479px) {
  .settings_icon {
    display: none;
  }
}
