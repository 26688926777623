input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.left_split {
  width: 50%;
  display: inline-flex;
  align-items: center;
}

.right-split {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub_description {
  margin-top: 0.4em;
  font-size: 0.8em;
  color: #454545;
}
.time_entry {
  display: flex;
  align-items: center;
}
.input_description {
  font-size: 12px;
  line-height: 18px;
  color: #666;
  margin-left: 10px;
  display: fl; /*ex;
  align-self: flex-end;*/
}
.colon {
  font-size: 16px;
  color: #666;
  font-weight: 700;
  margin-left: 2px;
  margin-right: 2px;
}
.outer_wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.input_title {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}
.systolic_wrapper {
  margin-right: 30px;
}
.audit_icon {
  margin-right: 10px;
}
