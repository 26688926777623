.wrapper{
  width: 95vw;
  margin-left:2%;
  margin-top: 2%;
  max-height: calc(100vh - 55px);
  flex-direction: column;
  align-items: center;
}
.heading1 {
  font-size: 2em;
  font-weight: 400;
  margin-bottom: 0.5em;
}
.profile_row {
  display: flex;
  margin-bottom: 1em;
}
.profile_row_title {
  font-weight: 700;
  margin-right: 5px;
}
.tab_panel {
  padding: 2em;
  min-height: 500px;
  min-width: 500px;
}
.section_title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.section_wrapper {
  margin-bottom: 30px;
}
.qr_description {
  width: 300px;
  margin-bottom: 10px;
}
.qr_left{

}
.qr_right {

}
.qr_wrapper {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.error {
  color: red;
  margin-top: 5px;
}
.options_2fa {
  margin-left: 40px;
}
.options_2fa_title {
  font-size: 18px;
  font-weight: 400;
}