.cancel_button {
  margin-top: 20px;
  cursor: pointer;
  color: #1166bb;
  font-size: 14px;
  font-weight: 700;
}
.complete_message {
  margin-bottom: 20px;
}
