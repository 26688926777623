.qa_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  /*  margin-bottom: -10px;*/
}
.modal_wrapper {
  padding: 20px;
  width: 400px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.modal_title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.password_wrapper {
  display: flex;
  align-self: center;
  justify-content: stretch;
  margin-top: 20px;
  margin-bottom: 10px;
  width: 80%;
}
.sign_for_label {
  margin-bottom: 6px;
}
.form_name_text {
  text-align: center;
}
.qa_tag {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #000;
  background-color: #eee;
  padding: 1px;
  margin-left: 10px;
  padding-left: 8px;
  padding-right: 8px;
  max-height: 20px;
  font-size: 10px;
}
.tag_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}
.check_mark {
  align-self: center;
  align-items: center;
  margin-left: 6px;
}
.modal_button_wrapper {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
}
.modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  margin-bottom: 10px;
}
.hover_menu {
  width: 240px;
  border: 1px solid #bbb;
  border-radius: 5px;
  z-index: 2;
  position: sticky;
  margin-left: 0px;
  margin-top: -4px;
  margin-left: -200px;
  background-color: white;
  font-size: 12px;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px;
}
.ellipsis_button {
  font-size: 16px;
  padding: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 6px;
  cursor: pointer;
  border-radius: 20px;
  margin-left: 10px;
  margin-bottom: -4px;
}
.menu_button {
  font-size: 16px;
  padding: 6px;
  padding-left: 8px;
  cursor: pointer;
}
.menu_button:hover {
  background-color: #e6effd;
}
.modal_wrapper {
  padding: 20px;
  width: 400px;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.modal_title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}
.modal_button_wrapper {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
}
.margin_bottom_10 {
  margin-bottom: 10px;
}
