.left-split {
  width: 50%;
}

.right-split {
  width: 60%;
}

.input_flex {
  display: flex;
  align-items: center;
}

.sub_description {
  margin-top: 0.4em;
  font-size: 0.8em;
  color: #454545;
}

.radio_group_flex {
  display: flex;
  /*  width: 50%;*/
  align-items: center;
  font-size: 0.95rem;
  line-height: 0.95rem;
}
.radio_group_vertical {
  display: inline-block;
  max-width: 50%;
  align-items: center;
  font-size: 0.95rem;
  line-height: 0.95rem;
}
.outer_wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.radio_item_wrapper {
  display: flex;
  align-items: flex-start;
  margin-right: 10px;
  padding: 3px;
  cursor: pointer;
}
.radio_text {
  margin-top: 2px;
}
.right_flex {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.audit_icon {
  margin-right: 10px;
}
