.modal_wrapper {
  padding: 20px;
  width: 800px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.modal_title {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
}
.modal_button_wrapper {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
  margin-top: 50px;
  padding-bottom: 40px;
}
.confirm_delete_button_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
}
.delete_confirmation_wrapper {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding-bottom: 40px;
}
.left_split {
  width: 50%;
  display: inline-flex;
  align-items: flex-start;
}

.right-split {
  width: 50%;
}

.input_flex {
  display: flex;
  align-items: center;
}
.outer_wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.file_display_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.clear_button {
  cursor: pointer;
  margin-left: 10px;
}
.filename {
  width: 360px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.filename_clickable {
  width: 360px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
