.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading1 {
  font-size: 2em;
  font-weight: 400;
  margin-bottom: 0.5em;
}
.profile_row {
  display: flex;
  margin-bottom: 1em;
}
.profile_row_title {
  font-weight: 700;
  margin-right: 5px;
}
.tab_panel {
  padding: 2em;
  min-height: 500px;
  min-width: 500px;
}
.section_title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.section_wrapper {
  margin-bottom: 30px;
}
.qr_description {
  width: 300px;
  margin-bottom: 10px;
}
.qr_left {
}
.qr_right {
}
.qr_wrapper {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.error {
  color: red;
  margin-top: 5px;
}
.options_2fa {
  margin-left: 40px;
}
.options_2fa_title {
  font-size: 18px;
  font-weight: 400;
}
.selected_row {
  background: #ddd;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.unselected_row {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}
.column_wrapper {
  overflow: scroll;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
