#leftFlyoutMenu {
  position: absolute;
  left: 0px;
  top: 56px;
  height: calc(100dvh - 50px);
  background-color: #fcfcfc;
  z-index: 10;
  border-right: solid 1px #ccc;
  transition: width 0.2s;
  margin-right: 0px;
  box-shadow: 0px 0px 20px -5px rgba(21, 27, 38, 0.04);
  overflow: auto;
  display: inline-flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-top: 10px;
  border-radius: 0px 10px 0px 0px;
}

.show {
  width: 250px;
}

.hide {
  width: 0px;
}
.sidebar_button_active {
  width: 50px;
  height: 50px;
  background-color: #5b60ab;
  border: 1px solid #bbb;
  border-radius: 6px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar_button {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 6px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar_button:hover {
  background-color: #f3f3f3;
}

.module_button_text {
  font-size: 0.7rem;
}

.button_wrapper {
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.logout {
  position: absolute;
  bottom: 0;
  margin-bottom: 36px;
  margin-right: 20px;
  right: 0;
  cursor: pointer;
}
.version {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #eee;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 10px;
}
.study_select_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  color: #454545;
  margin-top: 6px;
  margin-bottom: 6px;
}
.not_activated {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;

  font-weight: 700;
  font-size: 14px;
  color: #454545;
  margin-top: 6px;
  margin-bottom: 6px;
}
