.history_title {
  display: flex;
  font-weight: 700;
}
.history_bar {
  flex-basis: 250px;
  padding-left: 10px;
  border-left: 1px solid #eee;
  max-height: calc(100dvh - 170px);
  overflow: scroll;
  flex-shrink: 0;
}
.log_record {
  margin-bottom: 20px;
  margin-left: 20px;
}
.history_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}
.filter_wrapper {
  display: flex;
  min-height: 26px;
  margin-bottom: 6px;
  margin-left: 10px;
  flex-wrap: wrap;
}
.filter_tag {
  border: 1px solid;
  border-radius: 10px;
  border-color: transparent;
  background: #eff0f2;
  color: #1a2f52;
  padding: 3px;
  padding-left: 10px;
  padding-right: 6px;
  font-size: 12px;
  font-weight: 500;
  margin: 1px;
  display: flex;
  align-items: center;
}
.filter_menu {
  display: flex;
}
.filter_menu_item {
}
.no_history {
  margin-top: 30px;
  text-align: center;
}
