.wrapper {
  display: flex;
  flex-direction: row;
}
.selected_row {
  background: #ddd;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.row {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.checkbox {
  padding: 0;
  height: 30px;
}
.column {
  margin-right: 100px;
  height: calc(100vh - 120px);
  overflow: scroll;
}
.version_wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.version_title {
  margin-right: 10px;
  font-size: 14px;
}
