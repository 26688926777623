.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #444;
}
.para {
  margin-bottom: 20px;
}
.buttons {
  display: flex;
  width: 70%;
  justify-content: space-around;
}
