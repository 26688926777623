.segment {
  margin-top: 1rem;
}
.children {
  margin-top: 1rem;
  margin-left: 1.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: scroll;
  max-height: calc(100dvh - 200px);
}
.top_wrapper {
  display: flex;
  flex-direction: row;
}
.top_split_left {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 50%;
}
.top_split_right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 50%;
}
.segment_children {
  display: flex;
  justify-content: stretch;
  flex-direction: row;
}
