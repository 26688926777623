.skip_message{
  font-size: 14px;
  margin-top: 10px;
  margin-left:10px;
}
.upload_button_wrapper{
  display: flex;
}
.files_wrapper{
  margin-top: 10px;
}