@media screen and (max-width: 479px) {
  .flex {
    width: 100vw;
    height: calc(100dvh - 55px);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }
  .mobile_flex {
    width: 100vw;
    height: calc(100dvh - 55px);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media screen and (min-width: 480px) {
  .flex {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header_block_left {
  display: inline-flex;
  align-items: center;
}
.table_header_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_action_icon {
  margin-left: 10px;
  color: #555;
  cursor: pointer;
}

.table_action_icon:hover {
  transition: 0.3s;
  color: blue;
}
.hover_menu {
  width: 200px;
  border: 1px solid #bbb;
  border-radius: 5px;
  z-index: 2;
  position: absolute;
  margin-left: 0px;
  margin-top: 0px;
  background-color: white;
  font-size: 12px;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.menu_button {
  font-size: 16px;
  padding: 6px;
  padding-left: 10px;
  cursor: pointer;
}
.menu_button:hover {
  background-color: #e6effd;
}
.column_menu {
  max-height: 300px;
  overflow: scroll;
  font-size: 12px;
}
.column_menu_button {
  font-size: 14px;
  padding: 6px;
  padding-left: 0px;
  cursor: pointer;
}
.checkbox {
  padding: 0;
  height: 20px;
}
.domain_drop_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.domain_drop_title {
  font-size: 14px;
  margin-right: 4px;
}
.domain_tile_wrapper {
  display: flex;
  flex-direction: row;
}
.domain_tile {
  border: 1px solid;
  border-radius: 10px;
  border-color: transparent;
  background: #eff0f2;
  color: #1a2f52;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 11px;
  font-weight: 500;
  margin: 1px;
}
.no_subjects_found {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no_rows_overlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.subject_list {
  flex-direction: column;
  overflow: scroll;
}
.recent_subjects {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.subject_tile {
  border: 0.3px solid #777;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.button_wrapper {
  margin-top: 10px;
  text-align: center;
}
.mobile_subject_title {
  font-weight: 700;
}
.table_wrapper {
  width: 100%;
  height: calc(100dvh - 140px);
}
.query_margin {
  margin-right: 3px;
  margin-left: 3px;
}
