.heading {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
}
.red {
  color: var(--heading-red-text);
}
.center {
  align-items: center;
  justify-content: center;
}
