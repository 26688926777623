.hover_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  max-width: 500px;
  min-height: 200px;
  border-radius: 5px;
  background-color: white;
  font-size: 14px;
  padding: 20px;
}
.confirmation {
  display: flex;
  margin-top: 20px;
  cursor: pointer;
}
.password_wrapper {
  text-align: center;
  margin-top: 40px;
}
.row {
  display: flex;
  align-items: center;
}
.question {
  width: 55%;
  margin-right: 5%;
}
.header {
  margin-bottom: 20px;
}
