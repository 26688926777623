.flex {
  width: 90vw;
  height: calc(100dvh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.header {
  display: flex;
  flex-direction: row;
}
.search_wrapper {
  margin-left: 50px;
}
