@import "colors.css";

.sidebar {
  height: calc(100vh - 77px);
  width: 210px;
  z-index: 2;
  background-color: transparent;
  overflow-x: hidden;
  background-color: #fdfcff;
  border-right: 1px solid #ddd;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0 0;
}

.visit_select_header {
  width: 220px;
  font-size: 12px;
  white-space: nowrap;
  overflow-x: hidden;
  background-color: #fff;
  display: inline-flex;
  justify-content: space-between;
  padding: 2px 20px 2px 5px;
}
.visit_select_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.sidebar_layer {
  width: 220px;
  font-size: 12px;
  border-top: 11px;
  border-bottom: 1px solid #ededed;
  white-space: nowrap;
  overflow-x: hidden;
  background-color: #fdfcff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sidebar_layer1 {
  padding: 2px 20px 2px 5px;
}

.sidebar_layer2 {
  padding: 2px 20px 2px 10px;
}
.sidebar_layer:hover {
  background-color: #f5f5f5;
}
.layer2_active {
  background-color: #e3edf9;
  font-weight: 500;
}
.query_notification_icon {
  color: var(--open-query-icon-color);
  font-size: 16px;
  line-height: 16px;
}

.sidebar_layer3 {
  padding: 2px 20px 2px 30px;
  width: 165px;
}
