@media screen and (min-width: 480px) {
  .breadcrumb_wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-left: 30px;
  }
}
@media screen and (max-width: 479px) {
  .breadcrumb_wrapper {
    display: none;
    align-items: flex-end;
    justify-content: center;
    margin-left: 30px;
  }
}
.breadcrumb {
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: #777;
  margin-right: 5px;
}

a {
  text-decoration: none;
  color: #777;
  transition: 0.5s;
  cursor: pointer;
}
a:hover {
  color: purple;
  transition: 0.5s;
}
