.loading_wrapper {
  display: flex;
  width: 100vw;
  height: 100dvh;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 20%;
  opacity: 0.1;
}
