@import "colors.css";

.notify_icon {
  color: var(--open-query-icon-color);
  margin-top: 1px;
}
.resolved_icon {
  color: var(--resolved-query-icon-color);
}
.responded_icon {
  color: var(--responded-query-icon-color);
}
.check_icon {
  color: var(--green-check-icon-color);
}
.data_icon {
  color: var(--data-icon-color);
}
.arrow_back_icon {
  color: #ddd;
}
.arrow_back_icon:hover {
  color: var(--green-check-icon-color);
}
.menu_open {
  color: #ddd;
  cursor: pointer;
}
.menu_open:hover {
  color: var(--green-check-icon-color);
}
.refresh_icon {
  color: #999;
  cursor: pointer;
}
.refresh_icon:hover {
  color: var(--green-check-icon-color);
}
.close_icon {
  color: #999;
  cursor: pointer;
  margin-top: 0.5px;
}
.close_icon:hover {
  color: var(--green-check-icon-color);
}
.audit_icon {
  color: var(--audit-icon-color);
  cursor: pointer;
}
.audit_icon:hover,
.audit_icon_selected {
  color: var(--green-check-icon-color);
  cursor: pointer;
}
