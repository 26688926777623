.hide {
  display: none;
  transition-duration: 3s;
}
.show {
  display: inline-flex;
  flex-direction: column;
  transition-duration: 3s;
}
.button_wrapper {
  display: flex;
  width: 100%;
  align-content: flex-end;
  justify-content: flex-end;
  margin-top: 20px;
}
.button {
  border: 1px solid #999;
  border-radius: 20px;
  padding: 4px 10px 4px 10px;
  background-color: transparent;
  color: #666;
  font-weight: 700;
  margin-left: 10px;
  cursor: pointer;
}
.button:hover {
  background-color: #666;
  color: white;
}
.hover_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 500px;
  min-height: 300px;
  border-radius: 5px;
  background-color: white;
  font-size: 14px;
  padding: 20px;
}
.date {
  font-size: 10px;
  margin-bottom: 0px;
  color: #555;
}
.left_message {
  text-align: left;
  margin-bottom: 6px;
}
.right_message {
  text-align: right;
  margin-bottom: 10px;
}
.message_box_title {
  font-weight: 700;
  text-align: left;
  margin-bottom: 16px;
  margin-right: 6px;
  font-size: 15px;
  color: #444;
}
.title_flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.label {
  font-weight: 600;
  color: #444;
  margin-right: 6px;
}
