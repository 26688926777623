.wrapper {
  background: #fff;
  height: 100vh;
  color: #222;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 100px;
  max-width: 800px;
}
.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}
.sigWrapper {
  width: 400px;
  height: 100px;
  border: 1px solid #bbb;
  border-radius: 4px;
}
.sigPad {
  width: 100%;
  height: 100%;
}
.block {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.text_field_title {
  font-size: 14px;
}
.small_block {
  margin-top: 10px;
  margin-bottom: 10px;
}
