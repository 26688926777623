.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 140px;
}
.subject_text {
  color: #444;
  font-size: 20px;
}
.subject_id {
  color: #186bcc;
  font-size: 26px;
}
