.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
  overflow: scroll;
}
.edit_form_wrapper {
  /*  height: calc(100vh - 300px);*/
  overflow: scroll;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.heading1 {
  font-size: 2em;
  font-weight: 400;
}
.role_access_window {
  display: flex;
  flex: 1;
  margin-bottom: 1em;
  max-width: 800px;
  margin-left: 40px;
}
.profile_row {
  display: flex;
  margin-bottom: 1em;
  max-width: 400px;
}
.profile_row_title {
  font-weight: 700;
  margin-right: 5px;
}
.tab_panel {
  padding: 2em;
  min-height: 500px;
  min-width: 500px;
}
.section_title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.section_wrapper {
  margin-bottom: 30px;
}
.qr_description {
  width: 300px;
  margin-bottom: 10px;
}
.qr_left {
}
.qr_right {
}
.qr_wrapper {
  display: flex;
  align-items: center;
  margin-left: 30px;
}
.error {
  color: red;
  margin-top: 5px;
}
.options_2fa {
  margin-left: 40px;
}
.options_2fa_title {
  font-size: 18px;
  font-weight: 400;
}
.role_wrapper {
  min-width: 300px;
  padding: 20px;
}
.role_row {
  display: flex;
  align-items: center;
}
.checkbox {
  padding: 0;
  height: 30px;
}
.role_string_display {
  width: 100%;
  text-wrap: wrap;
}
.modify_roles_button {
  margin-top: 10px;
}
.dialog_button_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.dialog_wrapper {
  padding: 10px;
}
.roles_dialog_title {
  text-align: center;
  margin-top: 10px;
}
.save_button_wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
}
.activated {
  margin-bottom: 20px;
}
.created {
  margin-top: 10px;
  margin-bottom: 6px;
}

.activation_button {
  align-self: center;
  margin-bottom: 20px;
}
.column {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}
.column_left {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  width: 20vw;
}
.column_middle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  width: 50vw;
}
.column_right {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  width: 15vw;
}
.selected_row {
  background: #ddd;
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.unselected_row {
  cursor: pointer;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.role_settings_title {
  font-size: 18px;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 700;
}
