.wrapper {
  width: 80vw;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.stretch_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.no_rows_overlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.violation_icon {
  color: var(--deviation-icon-color);
  margin-top: 2px;
}
.icon_wrapper {
  display: flex;
}
