.wrapper {
  height: 100%;
  width: calc(100vw - 250px - 3rem);
  overflow: scroll;
}
.grid_row {
  display: flex;
}
.grid_heading {
  width: 100px;
}

.item {
  height: 35px;
  width: 180px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #f7f7f7;
  text-align: center;
  font-size: 14px;
}
.leftmost_item {
  display: flex;
  flex-direction: column;
  height: 35px;
  width: 300px;
  background-color: #fcfcfc;
  padding-left: 20px;
  border-bottom: 1px solid #eee;
  justify-content: center;
  font-size: 13px;
}
.header_height {
  height: 20px;
}

.white_background {
  background-color: white;
  border: none;
  color: #333;
}
.grey_background {
  background-color: #fcfcfc;
}
.log_forms_title {
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 10px;
}
.visit_map_vesion {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 12px;
  color: #444;
}
