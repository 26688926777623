.wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.link {
  cursor: pointer;
  color: blue;
  margin-top: 40px;
}
@media (max-width: 649px) {
  .title {
    text-align: center;
    text-wrap: wrap;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px;
    color: #444;
  }
}
@media (min-width: 650px) {
  .title {
    text-align: left;
    text-wrap: wrap;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0px;
    color: #444;
  }
}
@media (max-width: 649px) {
  .first_message {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media (min-width: 650px) {
  .first_message {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.treatment {
  color: #186bcc;
  font-weight: 700;
  max-width: 460px;
  text-wrap: wrap;
}
.subject_id {
  color: #186bcc;
  font-size: 26px;
}
.button_wrapper {
  margin-top: 1.5rem;
  display: flex;
  padding-left: 130px;
  padding-right: 130px;
  flex-direction: column;
  justify-content: center;
}
.header {
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 6px;
}
.flex_center {
  display: flex;
  justify-content: center;
}
.flex {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
}
.label {
  margin-right: 8px;
  font-weight: 700;
}
.not_available {
  text-align: center;
  line-height: 40px;
}
