@import "colors.css";

.query_icon_wrapper {
  width: 22px;
  padding-bottom: 0px;
  margin-left: -22px;
  display: flex;
}
.query_icon {
  color: var(--open-query-icon-color);
  cursor: pointer;
  margin-top: 2px;
}
.query_icon_resolved {
  color: var(--resolved-query-icon-color);
}
.query_icon_responded {
  color: var(--responded-query-icon-color);
}
.query_icon:hover {
  color: purple;
  transition: 0.5s;
}
.add_icon {
  color: var(--open-query-icon-color);
  transition: 0.5s;
}
.add_icon_wrapper {
  width: 22px;
  padding-bottom: 0px;
  margin-left: -22px;
  padding-top: 3px;
}

.top_wrapper {
  display: inline-flex;
  align-items: center;
  padding-left: 22px;
}
.title {
  display: inline-flex;
  flex-direction: row;
}
.title_pointer {
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
}
.deviation_icon {
  color: var(--deviation-icon-color);
  margin-top: 4px;
}
.violation_icon {
  color: var(--deviation-icon-color);
  margin-top: 4px;
}
.required {
  color: var(--red-error);
  margin-right: 1px;
  /*  color: red;*/
}
