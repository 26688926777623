.wrapper {
  height: 100dvh;
  width: 100dvw;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .login_card {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    /*  flex: 1;*/
    /*  padding: 1.5em;*/
    max-width: 28em;
    min-height: 22em;
    min-width: 400px;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
  }
}
@media only screen and (min-width: 601px) {
  .login_card {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    /*  flex: 1;*/
    /*  padding: 1.5em;*/
    /*    max-width: 28em;*/
    min-height: 22em;
    min-width: 450px;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
  }
}

.forgot_password {
  font-size: 0.9em;
  margin-top: 1.5em;
  cursor: pointer;
  color: #1166bb;
}
._2fa_message {
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 20px;
  line-height: 26px;
}
._2fa_button {
  width: 140px;
  align-self: center;
}
._2fa_button_wrapper {
  margin-top: 20px;
}
.cancel_button {
  margin-top: 20px;
  cursor: pointer;
  color: #999;
  font-size: 14px;
  font-weight: 700;
}
.try_another {
  margin-top: 20px;
  cursor: pointer;
  color: #1166bb;
  font-size: 14px;
  font-weight: 700;
}
._2fa_error {
  color: red;
  margin-top: 5px;
}
.error_message {
  color: red;
  margin-top: 20px;
}
.staging_text {
  margin-top: 20px;
  color: var(--heading-red-text);
  font-weight: 700;
}
.help_text {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #666;
  margin-top: 16px;
}
