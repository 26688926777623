.full_page{
  height: 100vh;
  width: 100vw;
}
.wrapper {
  width: 100vw;
  height: calc(100vh - 55px);
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.login-card{
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  width: 28em;
  min-height: 22em;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.margin-b-1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.margin-b-2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.asc-main-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100vw;
  z-index: 1;
  flex: 1; 
}