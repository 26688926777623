.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}
.tab_wrapper {
  max-width: calc(100vw - 290px);
  padding-top: 2rem;
  padding-left: 3rem;
}
.tab_panel {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100vh - 200px);
  padding-top: 2rem;
  overflow: scroll;
}
.subject_title {
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 18px;
}
.tile_label {
  width: 90px;
  font-size: 14px;
}
.tiles {
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
