.wrapper {
  padding: 0 3rem 0 3rem;
  font-size: 0.95em;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  height: calc(100vh - 55px);
  overflow: scroll;
}

.entry_row {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid #bbb;
}
.internal_editable_text_field {
  padding: 5px;
  cursor: pointer;
}
.options_row {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}
.name_wrapper {
  display: flex;
  width: 1000px;
  height: 40px;
  align-items: center;
  margin-bottom: 10px;
}
.editable_text_title {
  width: 800px;
}
.editable_text {
  width: 100%;
}
.row_select {
  width: 25%;
}
.type_select {
  display: flex;
  align-items: center;
}
.row_name {
  width: 400px;
}
.add_button {
  border: 1px solid black;
  width: 80px;
  padding: 3px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 6px;
}
.delete_button {
  border: 1px solid black;
  width: 80px;
  height: 18px;
  padding: 3px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 6px;
}
.vertical_meta_option {
  margin-left: 15px;
}
.flex {
  display: flex;
}
.flex_row {
  display: flex;
  flex-direction: row;
}
.crf_builder {
  margin-top: 10px;
  font-weight: 700;
  text-align: center;
}
.field_id {
  font-weight: 700;
}
.order_buttons {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}
.data_column {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  height: 100%;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.variable_name_row {
  display: flex;
  align-items: center;
}
.read_only_row {
  display: flex;
  align-items: center;
}
.footer {
  position: absolute;
  bottom: 0;
  background: white;
  width: 100dvw;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top: 1px solid #bbb;
  margin-left: -50px;
  display: flex;
  justify-content: center;
}
.spacer {
  height: 200px;
  width: 100vw;
}
