.sidebar {
  height: calc(100vh - 55px);
  width: 70px;
  z-index: 2;
  background-color: transparent;
  overflow-x: hidden; 
  background-color:#fff;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0 0;
  align-self: flex-start;
}

.sidebar_button_active {
  width: 50px;
  height: 50px;
  background-color: #383838;
  border: 1px solid #bbb;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar_button {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}