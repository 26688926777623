
.left_split {
  width: 40%;
  display: inline-flex;
  align-items: center;
}

.right-split {
  width: 60%;
}

.input_flex {
  display: flex;
  align-items: center;
}

.sub_description {
  margin-top: 0.4em;
  font-size: 0.8em;
  color: #454545;
}
.dropdown_entry{
  display: flex;
  align-items: center;
}
.input_description{
  font-size: 12px;
  line-height: 18px;
  color: #666;
  margin-left: 10px;

}
.colon {
  font-size: 16px;
  color: #666;
  font-weight: 700;
  margin-left: 2px;
  margin-right: 2px;
}
.outer_wrapper{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
}
.modal_wrapper{
  display: flex;
  align-self: center;
  background-color: white;
  max-width: 1000px;
  max-height: 100vh;
  padding: 10px;
  overflow: scroll;
}

