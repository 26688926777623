.sidebar {
  height: calc(100dvh - 55px);
  flex-basis: 300px;
  flex-shrink: 0;
  z-index: 2;
  background-color: transparent;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #fdfcff;
  border-right: 1px solid #ddd;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 0px 0px;
}

.visit_select_header {
  width: 220px;
  font-size: 12px;
  white-space: nowrap;
  overflow-x: hidden;
  display: inline-flex;
  justify-content: space-between;
  padding: 2px 20px 2px 5px;
}
.visit_select_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.sidebar_layer {
  width: 270px;
  font-size: 12px;
  border-top: 11px;
  border-bottom: 1px solid #ededed;
  overflow-x: hidden;
  background-color: #fdfcff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sidebar_layer1 {
  padding: 2px 20px 2px 5px;
}

.sidebar_layer2 {
  padding: 2px 20px 2px 20px;
}

.layer2_active {
  background-color: #e3edf9;
  font-weight: 500;
}

.sidebar_layer3 {
  padding: 2px 20px 2px 30px;
}
.sidebar_text {
  display: inline;
  width: 270px;
  /*  height: 60px;*/
  /*  word-break: break-all;*/
  overflow-wrap: anywhere;
  white-space: wrap;
}
.icon_tags {
  display: flex;
}
.count_left {
  margin-right: 3px;
}
.count_right {
  margin-left: 3px;
}
.close_icon {
  width: 100%;
  text-align: right;
  margin-top: 4px;
  margin-bottom: -16px;
  cursor: pointer;
}
.close_icon:hover {
  color: blue;
}
