.flex {
  width: 90vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.header {
  display: flex;
  flex-direction: row;
}
.search_wrapper {
  margin-left: 50px;
}
.filter_wrapper {
  display: flex;
  margin-bottom: 10px;
}
.filter_item {
  margin-right: 10px;
}
