.table_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.table_header_left {
  display: flex;
}
.filter_item {
  margin-right: 10px;
}
.application_dropdown {
  margin-right: 10px;
}
