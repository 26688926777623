.row {
  display: flex;
  align-items: center;
}
.label {
  margin-right: 10px;
}
.title {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #444;
}
.wrapper {
  width: 400px;
}
.button_wrapper {
  margin-top: 1.5rem;
  display: flex;
  padding-left: 130px;
  padding-right: 130px;
  flex-direction: column;
  justify-content: center;
}
.check_mark {
  align-self: center;
}
.econsent_success_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
