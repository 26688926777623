.title {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #444;
}
.domain_card_wrapper {
  border: 1px solid #c4cafb;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.domain_card_wrapper:hover {
  background: #f7f8fd;
}
