.wrapper {
  padding: 0 3rem 0rem 3rem;
  font-size: 0.95em;
  width: 100%;
  max-width: 1000px;
  height: calc(100vh - 55px);
  overflow: scroll;
}

.input_flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.left-split {
  width: 50%;
}

.right-split {
  width: 50%;
}

.checkbox_row {
  /*  font-size: 12px;*/
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  padding: 0;
  height: 30px;
}

.radio_label {
  font-size: 0.8em;
}
.outer_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}
.column {
  width: 50%;
  text-align: center;
}
