.hover_menu {
  width: 150px;
  border: 1px solid #bbb;
  border-radius: 5px;
  z-index: 2;
  position: absolute;
  margin-left: 0px;
  margin-top: 0px;
  right: 140px;
  background-color: white;
  font-size: 12px;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.table_action_icon {
  margin-left: 10px;
  color: #555;
  cursor: pointer;
}

.table_action_icon:hover {
  transition: 0.3s;
  color: blue;
}
.menu_button {
  font-size: 16px;
  padding: 6px;
  padding-left: 10px;
  cursor: pointer;
}
.menu_button:hover {
  background-color: #e6effd;
}
