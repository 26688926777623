.flex {
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80vw;
}
.search_element {
  margin-right: 10px;
  margin-bottom: 6px;
}
.filter_wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.filter_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.clear_all {
  color: #999;
  cursor: pointer;
}
.clear_all:hover {
  color: #1b4e99;
}
.filter_title {
  margin-bottom: 6px;
}
