.add_button {
  cursor: pointer;
}
.button {
  padding: 5px;
  border: 1px solid #bbb;
  border-radius: 3px;
  curor: pointer;
}
.entry_row {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
}
.internal_editable_text_field {
  padding: 5px;
}
.options_row {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
}
.name_wrapper {
  width: 100%;
  height: 40px;
  border: 1px solid #bbb;
}
.editable_text {
  width: 100%;
}
.row_select {
  width: 20%;
}
.row_name {
  width: 20%;
}
.add_button {
  border: 1px solid black;
  width: 50px;
  padding: 3px;
  border-radius: 3px;
}
.crf_tile {
  margin-left: 10px;
  border: 1px solid #bbb;
  padding: 3px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 3px;
  cursor: pointer;
}
.crf_tile:hover {
  background: #eee;
}
