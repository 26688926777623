:root {
  --open-query-icon-color: #ff5003;
  --resolved-query-icon-color: #c5c6ca;
  --deviation-icon-color: #e82c23;
  --heading-red-text: #e66767;
  --responded-query-icon-color: #0067c2;
  --green-check-icon-color: #0067c2;
  --data-icon-color: #c5c6ca;
  --red-error: #d32f2f;
  --audit-icon-color: #e1e2e6;
}
