.row {
  display: flex;
}

.element {
  margin-right: 26px;
}
.strong {
  font-weight: 700;
  color: #444;
}
.path {
  margin-left: 4px;
}
.clickable {
  cursor: pointer;
}
.hover_menu {
  width: 200px;
  border: 1px solid #bbb;
  border-radius: 5px;
  z-index: 2;
  position: absolute;
  margin-left: 0px;
  margin-top: 40px;
  background-color: white;
  font-size: 12px;
  justify-content: space-between;
  padding-top: 4px;
  padding-bottom: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  color: #1b4e99;
}
