.wrapper {
  max-width: 100dvw;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading1 {
  font-size: 2em;
  font-weight: 400;
  margin-bottom: 1em;
}
.profile_row {
  display: flex;
  margin-bottom: 1em;
}
.profile_row_title {
  font-weight: 700;
  margin-right: 5px;
}
.tab_panel {
  padding: 2em;
  min-height: 400px;
  max-width: 100dvw;
}
.section_title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.section_wrapper {
  margin-bottom: 30px;
}

.qr_left {
}
.qr_right {
}

.error {
  color: red;
  margin-top: 5px;
}

.options_2fa_title {
  font-size: 18px;
  font-weight: 400;
}
.tab_panel_wrapper {
  max-width: 100dvw;
}

@media screen and (max-width: 479px) {
  .qr_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }
  .qr_description {
    max-width: 100dvw;
    margin-bottom: 10px;
  }
  .options_2fa {
    margin-left: 20px;
  }
  .auth_wrapper {
    height: calc(100dvh - 220px);
    overflow: scroll;
  }
}
@media screen and (min-width: 480px) {
  .qr_wrapper {
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  .qr_description {
    width: 300px;
    margin-bottom: 10px;
  }
  .options_2fa {
    margin-left: 40px;
  }
}
