.password_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 80%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.buttons {
  display: flex;
  width: 200px;
  justify-content: space-between;
}
