.title {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #444;
}
.subject_entry_row {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.subject_entry_wrapper {
  margin-left: 40px;
}
.eligibility_row {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}
.left {
  max-width: 800px;
  padding-right: 16px;
}
.sub_title {
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}
.all_button {
  text-align: right;
  margin-bottom: 10px;
  margin-right: 18px;
}
.next_button {
  text-align: center;
  margin-bottom: 100px;
}
.wrapper {
  max-width: 800px;
  max-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  overflow: auto;
  padding: 10px;
}
.already_exists {
  color: #e66767;
  font-weight: 700;
  margin-bottom: 20px;
}
.existing_feedback_wrapper {
  display: flex;
  flex-direction: column;
}
.not_available {
  text-align: center;
  line-height: 40px;
}
