.margin-b-1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.margin-b-2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
  .logo {
    align-self: center;
    margin-bottom: 1em;
    width: 200px;
  }
}
@media only screen and (min-width: 601px) {
  .logo {
    align-self: center;
    margin-bottom: 1em;
    width: 350px;
  }
}
